
.formsize51{
    width:260px;
    text-align: center;

}

.formsize52{
    width:260px;
    text-align: center;
    margin-left: 10px;

}

.movedate{
    margin-left:10px;
}


.image{
    width: 180px;
    height: 120px;
    float: left;
    margin-right: 25px;
}

.imgtext{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 15px;
    margin-left: 20px;
    text-align: justify;
}

.icons{
    width:40px;
    height:40px;
    margin-left: 15px;
}

.icons2{
    width: 80px !important;;
    height: 80px !important;;
    margin-left: 15px;
}

.imgbut{
    float: right;
    margin-left: 10px;
}

.resatnImage{
    font-size: 25px;
}

.guestformat{
    width: 50% !important;
    float: left;
}

.roomdetformat{
    width: 40% !important;
    Float: left;
    
}
.closing{
    margin-left: 100%;
}

.halldiv{
    background-color: black;
    color: silver;
    font-family: Georgia, 'Times New Roman', Times, serif;
    
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
    width: 299px;
    height: 441px;
}

.imgdiv{
    width: 837px;
    height: 441px;
    margin-top: 10px;
    
}
.meeetingtitle{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 30px;
    display: none;
}



.meetingcols{
    border-style:solid;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-right: 20px;
    margin-top: 20px;
    width: 350px;

}

.meetingcols2{
    margin-left: 25px;
}

.evtag{
    font-size: 20px;
}

.aboutfmt2{
    width: 479px;
    height: 578px;
    float: right;
}

.formttxt4{
    color: rgb(83, 82, 82);
    font-size: 15px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height:35px;
    margin-top: 70px;
    
}

.textcolor{
    color:blue;
    height: 25px!important;
    font-size: 11px!important;
    
}

.textcolor2{
    color:red;
    height: 25px!important;
    font-size: 11px!important;
    
}

.adjust5{
    width: 280px !important;
    font-size: 14px;
}

.commentText{
    line-height: 2px!important;
}

.commentHeading{
    color:red;
}

.adjustcol{
    margin-left: 120px!important;
}

.formttxt5{
    color: rgb(83, 82, 82);
    font-size: 15px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-bottom: 40px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height:35px;
    margin-top: 30px;
    
}

.aboutfmt{
    width: 620px;
    height: 678px;
    float: right;
    object-fit: fill;
    margin-right: -110px;
    margin-top: 30px;
}

.blogDiv{
    display:flex;
    flex-direction: row;
    
}



.imageSizing{
    width: 300px !important;
    height: 320px !important;
    margin-bottom: 50px!important;
    /* background-color: black; */
}

.Resize{
    width: 100% !important;
    height: 100% !important;
}

.blogpage{
    height:auto;
    width:100%;
    margin-top: 10px;
    
}

.gap{
    margin-left: 28px!important;
}

.resizeCard{
    width: 420px !important;
    height: 560px !important;
}
.adjustText{
    line-height: 2px!important;
    font-size: 12px;
   color:orangered

}

.flipLeft{
    margin-left: 80px!important;
    width: 620px !important;
    height: auto !important;
    padding: 10px!important;
}

.contentText{
    font-family: Georgia, 'Times New Roman', Times, serif;
   text-align: justify;
}

.slogan{
    line-height: 2px!important;
    font-size: 12px;
    color:orangered;
    text-align: right;
    
}

.headingFormat{
    color:red
}

.itemPlacement{
    position: absolute;
    right:0px;
    bottom:0px;
    opacity: 0.9;
}

.colorSetting{
    width:100%;
    height:40px;
    text-align: center;
    background-color:black;
}

.setTxt{
    color:silver;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 12px!important;
    font-weight: bold;
    margin-top: 10px!important;
}

.fontSetting{
    position: absolute;
    left:0px;
    bottom:-38px;
    width: 120px !important;
}

.imagenewsize{
    width: 357px !important;
    height: 350px !important;
    margin-top: 10px!important;
    margin-left: 17px!important;
}

.blog9{
    margin-top: -416px!important;
    margin-right: -8px!important;
}


@media only screen and (max-width: 768px){
    .image{
        width: 100%;
        height: fit-content;
    }
    .imgtext{
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 12px;
        
       
    }
    
    
}


@media (max-width: 480px){
    .halldiv {
        display: none;
        
    }
    .imgdiv{
        width: 100%;
        height: fit-content;
        margin-left: 0px;
    }
    .meeetingtitle{
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 30px;
        display: block;
    }
    .meetingcols2{
        margin-left: 0px;
    }

    .meetingcols .largescrn{
        font-family: Georgia, 'Times New Roman', Times, serif;
        margin-top: 20px;
        
    }
    
}

@media (max-width: 991px){
    .halldiv {
        display: none;
        
    }
    .imgdiv{
        width: 100%!important;
        height: 100%!important;
        margin-top: 5px;
        
        
    }
    .meeetingtitle{
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 30px;
        display: block;
    }
    .meetingcols2{
        margin-left: 0px;
    }

    
    .meetingcols .largescrn{
        font-family: Georgia, 'Times New Roman', Times, serif;
        margin-top: 20px;
        
        
    }
    
    
}


@media (max-width: 1200px){
    .set1{
        position:relative;
        min-height:1350px;
        
    }    
    .set2{
        position:absolute;
        right: 10px;
        top: 5px;
        
    }

    .set3{
        position:absolute;
        left: 5px;
        top: 5px;
        
    }
    .lenght{
        width:190px;
    }

    .pixSize{
        width: 340px !important;
        height: 210px !important;
    }

    
    .textEdit{
        width:360px;
    }
    
    .set4{
        position:absolute;
        left: 5px;
        top: 600px;
        
    }

    .fixCol{
        width:100%;
    }

    .fixRow1{
        width:100%
    }

    .fixLine{
        width:320px;
        margin-left: 10px!important;
       
    }

    .aboutfmt2{
        width: 679px;
        height: 578px;
        float: right;
    }

    
    .pixSize2{
        width: 650px !important;
        height: 310px !important;
    }

    .con0{
        position:relative;
        height: 1100px;
        
        
    }

    .con1{
        position:absolute;
        left: 0px;
        top: 320px;
    }

    .con2{
        color: rgb(83, 82, 82);
        font-size: 13px;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        text-align: center;
        line-height: 1px;
       
    }

    .con3{
        position:absolute;
        left: 5px;
        top: 460px;
    }

    .formsize51{
        width: 596px!important;
        text-align: left;
        background-color: white;
        border-color: lightgrey!important;
    
    }

    .formsize52{
        width: 596px!important;
        text-align: left;
        background-color: white;
        margin-left: -3px;
        border-color: lightgrey!important;
    
    }
    
    
}


@media (max-width: 1000px){
    .set1{
        position:relative;
        min-height:1350px;
        
    }    
    .set2{
        position:absolute;
        right: 10px;
        top: 5px;
        
    }

    .set3{
        position:absolute;
        left: 5px;
        top: 5px;
        
    }
    .lenght{
        width:190px;
    }

    .pixSize{
        width: 340px !important;
        height: 210px !important;
    }

    
    .textEdit{
        width:360px;
    }
    
    .set4{
        position:absolute;
        left: 5px;
        top: 600px;
        
    }

    .fixCol{
        width:100%;
    }

    .fixRow1{
        width:100%
    }

    .fixLine{
        width:320px;
        margin-left: 10px!important;
       
    }

    .pixSize2{
        width: 650px !important;
        height: 310px !important;
    }

    .con0{
        position:relative;
        height: 1100px;
        
        
    }

    .con1{
        position:absolute;
        left: 0px;
        top: 320px;
    }

    .con2{
        color: rgb(83, 82, 82);
        font-size: 13px;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        text-align: center;
        line-height: 1px;
       
    }

    .con3{
        position:absolute;
        left: 5px;
        top: 460px;
    }

    .blog1{
        position:absolute;
        left: 5px;
        top: 30px;
    }

    .blog2{
        display:none;
    }

    .aboutfmt2{
        width: 679px;
        height: 578px;
        float: right;
    }

    .formsize51{
        width: 596px!important;
        text-align: left;
        background-color: white;
        border-color: lightgrey!important;
    
    }

    .formsize52{
        width: 596px!important;
        text-align: left;
        background-color: white;
        margin-left: -3px;
        border-color: lightgrey!important;
    
    }
    
}


@media (max-width: 700px){
    .set1{
        position:relative;
        min-height:1350px;
        
    }    
    .set2{
        position:absolute;
        right: 10px;
        top: 5px;
        
    }

    .commentText{
        line-height: 2px!important;
    }
    
    .commentHeading{
        color:red;
    }


    .set3{
        position:absolute;
        left: 5px;
        top: 5px;
        
    }
    .lenght{
        width:190px;
    }

    .pixSize{
        width: 340px !important;
        height: 210px !important;
    }

    .pixSize2{
        width: 350px !important;
        height: 210px !important;
    }

    
    .textEdit{
        width:360px;
    }
    
    .set4{
        position:absolute;
        left: 5px;
        top: 600px;
        
    }

    .fix4{
        position:absolute;
        left: 5px;
        top: 260px;
        
    }

    .con1{
        position:absolute;
        left: 0px;
        top: 200px;
    }

    .blogCard{
        position: relative;
    }

    .imageSizing{
        width: 290px !important;
        height: 310px !important;
        margin-bottom: 50px!important;
        margin-left: 25px!important;
        /* background-color: black; */
    }

    .blogpage{
        height: auto !important;
        min-height: 1500px!important;
        width:100%;
        margin-top: 15px;
        position: relative;
        
    }

    .resizeCard{
        width: 290px !important;
        height: 350px !important;
        position: absolute;
        left: 40px !important;
    }

    .imagenewsize{
        width: 100% !important;
        height: 198px !important;
        margin-top: 5px!important;
        margin-left: 1px!important;
    }

    .adjustText{
        line-height: 1px!important;
        font-size: 12px;
       color:orangered
    
    }

    .moveTitle{
        margin-top: -12px;
        font-size: 18px;
    }

    .gap{
        margin-left: 6px!important;
    }

    .makemobile{
        position: absolute;
        left: -50px !important;
        top:390px;
        width: 320px !important;
        text-align: justify!important;
    }

    .addComments{
        font-size: 15px!important;
    }

    

    .con2{
        color: rgb(83, 82, 82);
        font-size: 13px;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        text-align: center;
        line-height: 1px;
       
    }

    .con3{
        position:absolute;
        left: 5px;
        top: 380px;
    }

    .formsize51{
        width: 330px!important;
        text-align: left;
        background-color: white;
        border-color: lightgrey!important;
    
    }

    .formsize52{
        width: 330px!important;
        text-align: left;
        background-color: white;
        margin-left: -3px;
        border-color: lightgrey!important;
    
    }

    .fix5{
        position:absolute;
        left: 5px;
        top: 980px;
        
    }

    .fix3{
        position:absolute;
        left: 5px;
        top: 620px;
        
    }

    .fix0{
        position:relative;
        height: 1400px;
        
        
    }

    .con0{
        position:relative;
        height: 1000px;
        
        
    }

    .blog0{
        position:relative;
        height: 560px;
        
        
    }

    .fix6{
        position:absolute;
        left: 5px;
        top: 10px;
    }

    

    .fixCol{
        width:100%;
    }

    .fixRow1{
        width:100%
    }

    .fixLine{
        width:320px;
        margin-left: 10px!important;
       
    }

    .hideText{
        display: none;
    }

    .iconSet{
        width:320px;
    }
 
    
}






