.background10{
    height:450px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: rgb(23, 23, 71);
}

@media print{
    .printing{
        display: none !important;
    }
        
}

.adjust10{
    width:fit-content;
    font-size: 11px;
    height:fit-content;
    
}

.adjust2{
    width:fit-content;
    font-size: 12px;
    font-family: 'Times New Roman', Times, serif;
    
}

.textcolor{
    color:blue;
}


.move{
    margin-left:10px;
}


.formdesign10{
    margin-top: 0px;
    background-color: white;
    width: 280px;
    border-style: solid;
    border-color: white;
    border-radius: 5px;
    
}
.background101{
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: rgb(23, 23, 71);
}


.formdesign11{
    margin-top: 20px;
    background-color: white;
   
}

.btnwidth10{
    width: 100%;
    
}

.editselect{
    color: rgb(12, 12, 145);
    
}
.textdec{
    text-decoration: none;
}

.adjust{
    width:fit-content;
}

.space{
    margin-left: 20px;
}