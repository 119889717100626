.twitr{
    display: inline-block!important;
}

.facebk{
    display: inline-block!important;
}

.insta{
    display: inline-block!important;
}

.addrs{
    display: inline-block!important;
}

.navtext{
    text-decoration: none!important;
    color: gold!important;
    
}
.buttonformat{
    background-color: rgb(238, 226, 160);
    
}
.bg-dark{
    background-color: black!important;
}
.bookbtn{
    width: 100px;
    background-color: rgb(238, 226, 160);
    color: black;
    margin-left: 20px;
    height: 35px;
    text-decoration: none!important;

}
.bookbtn2{
    text-decoration: none!important;
    color: black;
}
/* .alignmenu{
    display:block;
    margin-left: 620px;
}

.alignmenu2{
    display:none;
} */

.shiftmenu{
    margin-left: 600px;
}

.shift{
    margin-right: 20px;
}

.shift2{
    padding: 30px!important;
}   

.envelopefrmt{
    color:rgb(236, 233, 233);
}

.size4{
    font-size: 20px!important;
    position:absolute;
    left:30px;
    top:35px;
    color: silver !important;
}

.size4:hover{
    color: tomato !important;
}

.size4cd{
    font-size: 20px!important;
    position:absolute;
    left:100px;
    top:35px;
    color: silver !important;
}

.size4cd:hover{
    color: tomato !important;
}

.size4b{
    font-size: 16px!important;
    position:absolute;
    left:30px;
    top:55px;
    color: silver !important;
}

.size4ba{
    font-size: 16px!important;
    position:absolute;
    left:80px;
    top:55px;
    color: silver !important;
}

.size5{
    font-size: 20px!important;
    position:absolute;
    left:70px;
    top:35px;
    color: silver !important;
}

.size5:hover{
    color: tomato !important;
}

.bg-dark{
    background-color: black!important;
}

.size3{
    font-size: 20px!important;
    position:absolute;
    right:30px;
    top:15px;
}

.menu{
    width: 100%;
    margin-Top:40px;
    background-color:black;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.menulist{
    color:silver;
    text-decoration: none!important;
    font-size: 25px !important;
    
    
}
.menulist:hover{
    color:tomato;
}

.envelopefrmt{
    color:rgb(236, 233, 233);
    margin-top: 13px;
}

.color{
    color: white !important;

}

.detext{
    color: silver;
    margin-right: 20px;
}

.logoresize{
    width:90px;
    height:85px;
    margin-left:40px;
}

.headerbackgrd3{
    background-color:black;
    height: 100%;
    

}

@media (max-width: 1000px) {
    .widthresize{
        width: 100%;
    }
    .logoresize{
        width:60px;
        height:55px;
        margin-left:20px;
    }

    .alignmenu{
        display: none;
    }

    .alignmenu2{
        display:block;
    }

    .size2{
        font-size: 30px!important;
        color: silver !important;
        position:absolute;
        right:15px;
        top:30px;
    }

    .size2:hover{
        color: tomato !important;
    }

    .menulist:hover{
        color:tomato;
    }

    .menu{
        width: 100%;
        margin-Top:40px;
        background-color:black;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
}

@media (min-width: 1600) {

    .alignmenu{
        display:block;
        margin-left: 620px;
    }
    
    .alignmenu2{
        display:none;
    }

    .fa{
        display:none;
    }
    
    
    .fa-bars{
        display:none;
    }

    .size2{
        display:none;
    }

    .size2:hover{
       display:none;
    }

    .hide{
        visibility: hidden;
    }

}